import { TextResources } from "@portal/assets/text/TextResources";

export const myPagesItems = [
  {
    path: "/",
    displayText: TextResources.MENU_DASHBOARD,
  },
  {
    path: "/equities",
    displayText: TextResources.MENU_EQUITIES,
  },
  {
    path: "/active-orders",
    displayText: TextResources.MENU_ORDERS,
  },
  {
    path: "/historic-orders",
    displayText: TextResources.MENU_HISTORIC_ORDERS,
  },
  {
    path: "/transactions",
    displayText: TextResources.MENU_TRANSACTIONS,
  },
  {
    path: "/transactions/ledger",
    displayText: TextResources.MENU_LEDGER_TRANSACTIONS,
  },
  {
    path: "/equities/historic",
    displayText: TextResources.MENU_HISTORIC_EQUITIES,
  },
];

export const fundItems = (savingRobotEnabled: boolean) => [
  {
    path: "/funds",
    displayText: TextResources.MENU_FUNDS,
  },
  {
    disabled: !savingRobotEnabled,
    path: "/robot",
    displayText: TextResources.MENU_ROBOT,
  },
  {
    path: "/createAgreement",
    displayText: TextResources.MENU_CREATE_AGREEMENT,
  },
];

export const stockItems = [
  {
    path: "/trader",
    displayText: TextResources.MENU_TRADER_DASHBOARD,
  },
  {
    path: "/trader/stocks",
    displayText: TextResources.MENU_STOCK_LIST,
  },
  {
    path: "/trader/news",
    displayText: TextResources.MENU_TRADER_NEWS,
  },
  {
    path: "/trader/calendar",
    displayText: TextResources.MENU_TRADER_CALENDAR,
  },
  {
    path: "/trader/reports",
    displayText: TextResources.MENU_TRADER_REPORTS,
  },
  {
    path: "/trader/favorites",
    displayText: TextResources.MENU_TRADER_FAVORTIES,
  },
  {
    path: "/trader/alarms",
    displayText: TextResources.MENU_TRADER_ALARMS,
  },
  {
    path: "/unlistedSecurities",
    displayText: TextResources.MENU_UNLISTED_SECURITIES,
  },
];

export const accountItems = (isCorporate: boolean, isFundTradingEnabled: boolean) => [
  {
    disabled: isCorporate,
    path: "/create-ask",
    displayText: TextResources.MENU_CREATE_ASK,
  },
  {
    disabled: isCorporate || !isFundTradingEnabled,
    path: "/ips",
    displayText: TextResources.IPS_CREATE,
  },
  {
    disabled: isCorporate,
    path: "/create-account",
    displayText: TextResources.MENU_CREATE_ACCOUNT,
  },
  {
    path: "/transfer?index=0",
    displayText: TextResources.MENU_TRANSFER_DEPOSIT,
  },
  {
    path: "/transfer?index=1",
    displayText: TextResources.MENU_TRANSFER_WITHDRAWAL,
  },
  {
    path: "/transfer?index=2",
    displayText: TextResources.MENU_NEW_ACCOUNT,
  },
  {
    path: "/withdrawal-agreement",
    displayText: TextResources.MENU_TRANSFER_WITHDRAWAL_AGREEMENT,
  },
  {
    disabled: isCorporate,
    path: "/move-ask",
    displayText: TextResources.MENU_MOVE_ASK,
  },
  {
    disabled: isCorporate,
    path: "/move-ask/overview",
    displayText: TextResources.MENU_MOVE_ASK_OVERVIEW,
  },
  {
    disabled: isCorporate,
    path: "/ask",
    displayText: TextResources.MENU_ASK_DETAILS,
  },
  {
    disabled: isCorporate || !isFundTradingEnabled,
    path: "/gift",
    displayText: TextResources.MENU_FUND_AS_GIFT,
  },
];

export const otherItems = (isCorporate: boolean) => [
  {
    path: "/costs",
    displayText: TextResources.MENU_COSTS,
  },

  {
    path: "/poa",
    displayText: TextResources.MENU_POA,
  },
  {
    path: "/mailbox",
    displayText: TextResources.MENU_MAILBOX,
  },
  {
    disabled: isCorporate,
    path: "/pension",
    displayText: TextResources.MENU_PENSION,
  },
  {
    path: "/subscriptions",
    displayText: TextResources.MENU_SUBSCRIPTIONS,
  },
  {
    path: "/profile",
    displayText: TextResources.MENU_MY_PROFILE,
  },
];
