import useSWR, { SWRResponse } from "swr";
import { IUsePortalMessage } from "./types";
import { IPortalMessage } from "@portal/types/api/IPortalMessage";
import commonSwrConfig from "../commonSwrConfig";

const usePortalMessage: IUsePortalMessage = () => {
  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, error, isLoading }: SWRResponse<IPortalMessage, string> = useSWR(
    `/api/portal/message`,
    fetcher,
    commonSwrConfig(),
  );

  return {
    data,
    error,
    isLoading,
  };
};

export default usePortalMessage;
