import { TextResources } from "@portal/assets/text/TextResources";

export interface IShortcut {
  path: string;
  displayText: string;
  hideAtSmallDevice?: boolean;
  hideAtMediumDevice?: boolean;
}

const fundItems = [
  {
    path: "/",
    displayText: TextResources.MENU_DASHBOARD,
  },
  {
    path: "/equities",
    displayText: TextResources.MENU_EQUITIES,
    hideAtSmallDevice: true,
  },
  {
    path: "/funds",
    displayText: TextResources.MENU_FUNDS,
    hideAtSmallDevice: true,
  },
  {
    path: "/active-orders",
    displayText: TextResources.MENU_ORDERS,
  },
  {
    path: "/transactions",
    displayText: TextResources.MENU_TRANSACTIONS,
    hideAtSmallDevice: true,
    hideAtMediumDevice: true,
  },
  {
    path: "/trader",
    displayText: TextResources.MENU_TRADER_DASHBOARD_FROM_FUNDS,
  },
  {
    path: "/robot",
    displayText: TextResources.MENU_SAVINGS_ROBOT,
    hideAtSmallDevice: true,
    hideAtMediumDevice: true,
  },
] as IShortcut[];

const stockItems = [
  {
    path: "/",
    displayText: TextResources.MENU_DASHBOARD,
  },
  {
    path: "/trader",
    displayText: TextResources.MENU_TRADER_DASHBOARD,
  },
  {
    path: "/trader/stocks",
    displayText: TextResources.MENU_STOCK_LIST,
    hideAtSmallDevice: true,
  },
  {
    path: "/trader/news",
    displayText: TextResources.MENU_TRADER_NEWS,
    hideAtSmallDevice: true,
  },
  {
    path: "/trader/calendar",
    displayText: TextResources.MENU_TRADER_CALENDAR,
    hideAtSmallDevice: true,
    hideAtMediumDevice: true,
  },
  {
    path: "/trader/orders",
    displayText: TextResources.MENU_ORDERS,
  },
  {
    path: "/trader/transactions",
    displayText: TextResources.MENU_TRANSACTIONS,
    hideAtSmallDevice: true,
    hideAtMediumDevice: true,
  },
] as IShortcut[];

export const getDesktopShortcuts = (
  pathname?: string,
  stockTradingEnabled?: boolean,
  fundTradingEnabled?: boolean,
  savingRobotEnabled?: boolean,
): IShortcut[] => {
  const trader = pathname?.includes("/trader");
  let desktopMenuItems = trader ? stockItems : fundItems;
  desktopMenuItems = !stockTradingEnabled ? desktopMenuItems.filter(item => !item.path.startsWith("/trader")) : desktopMenuItems;
  desktopMenuItems = !fundTradingEnabled ? desktopMenuItems.filter(item => item.path !== "/funds") : desktopMenuItems;
  desktopMenuItems = !savingRobotEnabled ? desktopMenuItems.filter(item => item.path !== "/robot") : desktopMenuItems;

  return desktopMenuItems;
};
