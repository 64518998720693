import useSWR, { SWRResponse } from "swr";
import { IFund } from "../../types/api/IFund";
import useMutateFunds from "./mutate";
import { IFundFetcherResponse, IBankUseFundList } from "./types";
import commonSwrConfig from "../commonSwrConfig";
import { mapFund } from "../useFund/helper";
import useCenterId from "../useCenterId";

const useBankFundList: IBankUseFundList = () => {
  const centerId = useCenterId();

  const fetcher = async (url: URL): Promise<IFundFetcherResponse> => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    const json = await response.json();
    const funds = json.map((f: IFund) => mapFund(f));
    return { funds, allFunds: funds };
  };

  const response: SWRResponse<IFundFetcherResponse, unknown> = useSWR(
    centerId ? `/api/funds?centerId=${centerId}` : null,
    fetcher,
    commonSwrConfig(),
  );
  const getFund = (isin: string) => {
    return response.data?.allFunds.find(fund => fund.isin === isin);
  };

  const mutations = useMutateFunds(centerId ?? "0000");

  return {
    funds: response.data?.funds,
    allFunds: response.data?.allFunds,
    getFund,
    ...mutations,
    error: response.error,
    isLoading: !response.data && !response.error,
  };
};

export default useBankFundList;
