import StatusBar from "@portal/components/atoms/statusBar";
import useSelectedPowerOfAttorney from "@portal/hooks/useSelectedPowerOfAttorney";
import { useMemo } from "react";
import NavBar from "../../NavBar";
import PageSpinner from "../../pageSpinner";
import useBank from "@portal/hooks/useBank";
import Typography from "@portal/components/atoms/typography";
import { TextResources } from "@portal/assets/text/TextResources";
import { useSession } from "next-auth/react";
import { ILayout } from "./types";
import { PortalFooter } from "../../footer";
import { NORNE_CENTERID } from "@portal/assets/banks";
import BankMessageDialog from "./bankMessageDialog";
import usePortalMessage from "@portal/hooks/usePortalMessage";
import NoAccessToFeature from "./noAccessToFeature";
import useVerifyAmlStatus from "@portal/hooks/useVerifyAmlStatus";
import Link from "next/link";
import useSelectedUserPoaForLoggedInUser from "@portal/hooks/useSelectedUserPoaForLoggedInUser";
import { PowerOfAttorneyType } from "@portal/types/api/IPoaInfo";
import NextTopLoader from "nextjs-toploader";

const Layout = ({ children, requiresStockPageEnabled, requiresFundPageEnabled, centerId }: React.PropsWithChildren<ILayout>) => {
  const { status } = useSession();
  const { isLoading } = useSelectedPowerOfAttorney();
  const { data: bankData, isLoading: bankIsLoading } = useBank();
  const { data: portalMessage } = usePortalMessage();
  const { amlVerified, loading: amlLoading } = useVerifyAmlStatus();

  const { data: poa, selectedUser } = useSelectedUserPoaForLoggedInUser();

  const hasAnyPortfolioWithFullAccess = useMemo(() => {
    return poa?.some(
      p =>
        p.customerNumber === selectedUser?.userId &&
        p.portfolios.some(
          po => po.powerOfAttorneyType === PowerOfAttorneyType.Full || po.powerOfAttorneyType === PowerOfAttorneyType.Child,
        ),
    );
  }, [poa, selectedUser?.userId]);

  if (bankIsLoading || isLoading) return <PageSpinner />;

  return (
    <div className={`flex flex-col w-full text-defaultText bg-greyBackground min-h-[100vh]`}>
      <NextTopLoader />
      <div className="print:hidden bg-headerBackground text-headerMenuText">
        <div className="flex border-b">
          <NavBar centerId={centerId ?? NORNE_CENTERID} />
        </div>
      </div>
      <div className="container mx-auto mb-3 mt-3 md:mb-0">
        {!amlVerified && !amlLoading && (
          <div className="print:hidden">
            <StatusBar>
              <div className="flex">
                <Typography variant="Body">
                  {TextResources.ADVISOR_MESSAGE_AML_NOT_VERIFIED}{" "}
                  <Link href="/aml/form" className="text-blueHighlighted underline">
                    {TextResources.ADVISOR_MESSAGE_AML_NOT_VERIFIED_LINK}
                  </Link>
                </Typography>
              </div>
            </StatusBar>
          </div>
        )}
        {selectedUser?.userId && !hasAnyPortfolioWithFullAccess && (
          <div className="print:hidden">
            <StatusBar>
              <div className="flex">
                <Typography variant="Body">{TextResources.LIMITED_ACCESS_AT_SELECTED_USER}</Typography>
              </div>
            </StatusBar>
          </div>
        )}
        {portalMessage && portalMessage.enabled && (
          <div className="print:hidden">
            <StatusBar>
              <div className="flex">
                <Typography variant="Body">{portalMessage.message}</Typography>
              </div>
            </StatusBar>
          </div>
        )}
        {status === "unauthenticated" && (
          <div className="print:hidden">
            <StatusBar>
              <Typography variant="Body">{TextResources.STATUS_BAR}</Typography>
            </StatusBar>
          </div>
        )}
        <div className="mb-3">
          {!isLoading && (
            <>
              {(requiresStockPageEnabled && !bankData?.stockTradingEnabled) ||
              (requiresFundPageEnabled && !bankData?.fundTradingEnabled) ? (
                <NoAccessToFeature />
              ) : (
                children
              )}
            </>
          )}
        </div>
      </div>
      <PortalFooter centerId={centerId ?? NORNE_CENTERID} />
      <BankMessageDialog />
    </div>
  );
};

export default Layout;
