import styled from "styled-components";

interface Props {
  active: boolean;
}

export const ShortcutItemMenuButton = styled.button<Props>`
  display: flex;
  align-items: end;
  padding: 0 4px;
  border-bottom-width: 2px;
  border-color: ${props => (props.active ? "var(--color-secondary)" : "transparent")};
`;
