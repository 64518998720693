import useBanks from "@portal/hooks/useBank";
import ShortcutItem from "@portal/components/organisms/NavBar/desktopShortcuts/shortcutItem";
import { usePathname } from "next/navigation";
import { getDesktopShortcuts } from "./desktopShortcutsConfig";
import ShortcutDropdown from "../shortcutsDropdown";
import useNotificationCount from "@portal/hooks/useNotificationCount";

const DesktopShortcuts = () => {
  const pathname = usePathname();
  const { data: bankData } = useBanks();

  const { showNotifications, notificationCount } = useNotificationCount();

  const desktopMenuItems = getDesktopShortcuts(
    pathname ?? undefined,
    bankData?.stockTradingEnabled,
    bankData?.fundTradingEnabled,
    bankData?.savingRobotEnabled,
  );

  return (
    <div className="hidden lg:flex space-x-4 pb-2 pl-3 items-end justify-start w-full">
      {desktopMenuItems.map(item => (
        <ShortcutItem
          key={item.displayText}
          displayText={item.displayText}
          path={item.path}
          showNotifications={showNotifications && (item.path === "/active-orders" || item.path === "/trader/orders")}
          notificationCount={notificationCount}
          hideAtMediumDevice={item.hideAtMediumDevice}
        />
      ))}
      <ShortcutDropdown
        items={desktopMenuItems
          .filter(item => item.hideAtMediumDevice)
          .map(fi => {
            return {
              ...fi,
              showNotifications: false,
              notificationCount: notificationCount,
            };
          })}
        notificationCount={0}
      />
    </div>
  );
};

export default DesktopShortcuts;
