import Typography from "@portal/components/atoms/typography";
import Icon from "@portal/components/atoms/icons";
import { MenuItem as HeadlessMenuItem } from "@headlessui/react";
import { FC, useState } from "react";
import { IMenuGroupProps, IMenuItem } from "./types";
import { useRouter } from "nextjs-toploader/app";
import { NotificationItem } from "@portal/components/atoms/notificationItem";

const MenuGroup: FC<IMenuGroupProps> = ({ title, icon, menuItems, showNotifications, notificationCount }) => {
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);

  const renderItem = (item: IMenuItem) => {
    if (item.disabled) return null;

    return (
      <HeadlessMenuItem key={item.displayText} as="a" onClick={() => router.push(item.path)}>
        {({ focus }) => (
          <div className="flex items-start">
            <div className={`hover:cursor-pointer ${focus && "text-red"}`}>{item.displayText}</div>
            {item.displayText === "Ordre" && <NotificationItem count={notificationCount} showNotifications={showNotifications} />}
          </div>
        )}
      </HeadlessMenuItem>
    );
  };

  return (
    <div className="flex flex-col space-y-1 items-start border-b md:border-b-0 border-greyBorder">
      <div
        className="flex justify-between w-full hover:cursor-pointer md:hover:cursor-default"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="flex mb-2 space-x-2 items-center">
          <Icon name={icon} size="small" />
          <Typography variant="H6">{title}</Typography>
          <span className="md:hidden">
            {title === "Mine sider" && <NotificationItem count={notificationCount} showNotifications={showNotifications} />}
          </span>
        </div>
        <span className="md:hidden">
          {isActive ? <Icon name="chevronUpIcon" size="small" /> : <Icon name="chevronDownIcon" size="small" />}
        </span>
      </div>
      <div className={`${!isActive ? "hidden" : "flex"} md:flex flex-col space-y-2.5 items-start pb-4`}>
        {menuItems.map(item => renderItem(item))}
      </div>
    </div>
  );
};

export default MenuGroup;
