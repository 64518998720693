import { FC } from "react";
import { IShortcutDropdownProps } from "./types";
import Icon from "@portal/components/atoms/icons";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import ShortcutItem from "./shortcutItem";

const ShortcutDropdown: FC<IShortcutDropdownProps> = ({ items, openLeft = false }) => {
  if (!items || items.length === 0) return null;

  return (
    <div className="flex xl:hidden">
      <Menu as="div" className="relative inline-block text-left text-headerMenuText">
        {({ open }) => (
          <>
            <MenuButton className="flex items-center justify-center hover:text-secondary">
              <>{open ? <Icon name="ellipsisHorizontalCircleIcon" /> : <Icon name="ellipsisHorizontalIcon" />}</>
            </MenuButton>

            <MenuItems
              className={`bg-white rounded-md absolute ${
                openLeft ? "left-0" : "right-0"
              } mt-2 mr-[-8px] md:mr-0 border z-20 shadow-xl w-48`}
            >
              {items.map(item => (
                <ShortcutItem key={item.displayText} item={item} />
              ))}
            </MenuItems>
          </>
        )}
      </Menu>
    </div>
  );
};

export default ShortcutDropdown;
