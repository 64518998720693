import UserMenuItem from "../menuItem";
import { PoaUser } from "iron-session";
import { Session } from "next-auth";
import { TextResources } from "@portal/assets/text/TextResources";
import { useAppDispatch } from "@portal/redux/store";
import { switchPoaUser } from "@portal/redux/reducers/api/switchPoaUser/reducer";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { Spinner } from "@portal/components/atoms/spinner";
import Typography from "@portal/components/atoms/typography";
import useCenterId from "@portal/hooks/useCenterId";
import { IPoaInfo } from "@portal/types/api/IPoaInfo";

interface Props {
  selectedUser: PoaUser | undefined;
  session: Session | null;
  powerOfAttorneys: IPoaInfo[] | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Component to show the option for switching users in the UserMenu
 * @param interface
 * @returns a button to switch users.
 */
export const SwitchUserItem = ({ selectedUser, session, powerOfAttorneys, isOpen, setIsOpen }: Props) => {
  const dispatch = useAppDispatch();
  const centerId = useCenterId();
  const router = useRouter();
  const [isSwitchingUser, setIsSwitchingUser] = useState(false);

  const handleSwitchUser = async (userId: string) => {
    setIsSwitchingUser(true);
    await dispatch(switchPoaUser({ userId, centerId: centerId ?? "" }));
    setIsOpen(false);
    setIsSwitchingUser(false);
    router.push("/");
  };

  return (
    <>
      <UserMenuItem
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          setIsOpen(!isOpen);
          e.preventDefault();
        }}
        iconName="arrowsRightLeftIcon"
      >
        {TextResources.MENU_SWITCH_USER}
      </UserMenuItem>
      {isSwitchingUser ? (
        <div className="flex flex-row border-t border-greyBorder bg-greyLight py-2 px-3.5 space-x-4">
          <div>
            <Spinner size="small" />
          </div>
          <div>
            <Typography>{TextResources.MENU_SWITCH_USER_LOADING}</Typography>
          </div>
        </div>
      ) : (
        <>
          <UserMenuItem
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              handleSwitchUser(session?.user.id || "");
            }}
            className={isOpen ? "border-t border-greyBorder" : "hidden"}
            iconName="userHeroIcon"
            subItem
            leftSelectedIcon={!selectedUser?.userId || selectedUser?.userId === session?.user.id}
          >
            {`${session?.user?.name} (hovedbruker)` || ""}
          </UserMenuItem>

          {powerOfAttorneys &&
            powerOfAttorneys.map(poaItem => (
              <UserMenuItem
                disabled={poaItem.portfolios.every(p => p.status?.toLowerCase() === "s")}
                key={poaItem.customerNumber}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  handleSwitchUser(poaItem.customerNumber || "");
                }}
                className={isOpen ? "pr-10" : "hidden"}
                subItem
                iconName={poaItem.customerNumber?.length === 11 ? "userHeroIcon" : "buildingOfficeIcon"}
                leftSelectedIcon={selectedUser?.userId === poaItem.customerNumber}
              >{`${poaItem.firstName ? poaItem.firstName : ""} ${poaItem.lastName ? poaItem.lastName : ""}`}</UserMenuItem>
            ))}
        </>
      )}
    </>
  );
};
