import { useAppSelector } from "@portal/redux/store";
import { IUseNotficationCount } from "./types";

const useNotificationCount: IUseNotficationCount = () => {
  const orderCount =
    useAppSelector(state => state.other.api.orders.orders)?.filter(o => o.orderStatus !== "K" && o.orderStatus !== "S").length ??
    0;
  const switchOrderCount = useAppSelector(state => state.other.api.switchOrderCount.switchOrderCount);

  const notificationCount = orderCount + switchOrderCount;
  const showNotifications = notificationCount > 0;

  return { notificationCount, showNotifications };
};

export default useNotificationCount;
