export interface IPoaInfo {
  customerNumber: string;
  firstName: string;
  lastName: string;
  portfolios: IPoaPortfolio[];
}

export interface IPoaPortfolio {
  portfolioId: string;
  powerOfAttorneyType: PowerOfAttorneyType;
  status: string;
}

export enum PowerOfAttorneyType {
  Full = "Full",
  Child = "Child",
  Insight = "Insight",
}
