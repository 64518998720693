import Icon from "@portal/components/atoms/icons";
import { DialogComponent } from "../../../DialogComponent";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSession } from "next-auth/react";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import { TextResources } from "@portal/assets/text/TextResources";
import useBank from "@portal/hooks/useBank";

const CookieName = "bankMessage";

const setBankMessageCookie = (centerId: string, messageId: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 120 * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${CookieName}=${centerId}|${messageId};${expires};path=/;SameSite=Strict`;
};

const hasBankMessageCookie = (centerId?: string, messageId?: string): boolean => {
  if (!centerId || !messageId) return false;
  const decodedCookie = decodeURIComponent(document.cookie);
  return decodedCookie
    .split(";")
    .some(cookie => cookie.trim().startsWith(CookieName) && cookie.split("=").includes(`${centerId}|${messageId}`));
};

const BankMessageDialog = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { data: bankData } = useBank();
  const { data: session } = useSession();

  const onCloseDialog = () => {
    setBankMessageCookie(session?.centerId ?? NORNE_CENTERID, bankData?.messageGuid ?? "");
    setShowDialog(false);
  };

  useEffect(() => {
    if (bankData?.messageEnabled && !hasBankMessageCookie(session?.centerId, bankData?.messageGuid)) {
      setShowDialog(true);
    }
  }, [bankData, session]);

  return (
    <DialogComponent
      open={showDialog}
      onClose={() => setShowDialog(false)}
      panelClassName="top-40 w-3/4 rounded-lg bg-white border border-greyBorder p-4"
    >
      <div className="flex flex-row justify-end">
        <button onClick={onCloseDialog} className="focus:outline-1 focus:outline-blueLight">
          <Icon name="xMarkIcon" size="xsmall" />
        </button>
      </div>
      <div className="flex flex-col space-y-3">
        <div>
          <ReactMarkdown className="whitespace-pre-wrap [&_a]:text-blueLight">{bankData?.message ?? ""}</ReactMarkdown>
        </div>
        <div className="flex justify-center">
          <ButtonComponent primary text={TextResources.BANK_MESSAGE_CLOSE_LABEL} onClick={onCloseDialog} />
        </div>
      </div>
    </DialogComponent>
  );
};

export default BankMessageDialog;
