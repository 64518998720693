import Item from "./item";
import useBank from "@portal/hooks/useBank";
import ShortcutDropdown from "../shortcutsDropdown";
import { usePathname } from "next/navigation";
import { getDesktopShortcuts } from "../desktopShortcuts/desktopShortcutsConfig";
import useNotificationCount from "@portal/hooks/useNotificationCount";

const SmallDeviceShortcuts = () => {
  const pathname = usePathname();
  const { data: bankData } = useBank();
  const { showNotifications, notificationCount } = useNotificationCount();

  const desktopMenuItems = getDesktopShortcuts(
    pathname ?? undefined,
    bankData?.stockTradingEnabled,
    bankData?.fundTradingEnabled,
    bankData?.savingRobotEnabled,
  );

  return (
    <div className="flex lg:hidden space-x-2 sm:space-x-4 pb-1 pt-1 pl-3 md:pl-0 items-center justify-start w-full">
      {desktopMenuItems.map(item => (
        <Item
          key={item.displayText}
          {...item}
          showNotifications={showNotifications && (item.path === "/active-orders" || item.path === "/trader/orders")}
          notificationCount={notificationCount}
        />
      ))}
      <div className="flex sm:hidden">
        <ShortcutDropdown
          items={desktopMenuItems
            .filter(item => item.hideAtSmallDevice)
            .map(fi => {
              return {
                ...fi,
              };
            })}
          notificationCount={notificationCount}
          openLeft={!bankData?.stockTradingEnabled}
        />
      </div>
      <div className="hidden sm:flex">
        <ShortcutDropdown
          items={desktopMenuItems
            .filter(item => item.hideAtMediumDevice)
            .map(fi => {
              return {
                ...fi,
              };
            })}
          notificationCount={notificationCount}
        />
      </div>
    </div>
  );
};

export default SmallDeviceShortcuts;
