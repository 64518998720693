import Typography from "@portal/components/atoms/typography";
import { usePathname } from "next/navigation";
import { FC, useEffect, useState } from "react";
import { IShortcutItem } from "./types";
import { NotificationItem } from "@portal/components/atoms/notificationItem";
import { ShortcutItemMenuButton } from "./styled/ShortcutItemMenuButton";
import { useRouter } from "nextjs-toploader/app";

const ShortcutItem: FC<IShortcutItem> = ({ displayText, path, showNotifications, hideAtMediumDevice, notificationCount }) => {
  const [active, setActive] = useState(false);
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (path === "/robot" && pathname?.startsWith("/robot")) {
      setActive(true);
    } else {
      setActive(pathname === path);
    }
  }, [pathname, path]);

  return (
    <div
      className={
        hideAtMediumDevice
          ? `hidden xl:flex text-headerMenuText hover:text-secondary`
          : "flex text-headerMenuText hover:text-secondary"
      }
    >
      <ShortcutItemMenuButton
        active={active}
        onClick={() => {
          router.push(path);
        }}
      >
        <div className="flex">
          <Typography variant="Lead">{displayText}</Typography>
        </div>
        {showNotifications && (
          <div className="flex pb-1.5 ml-[-2px]">
            <NotificationItem count={notificationCount} showNotifications={showNotifications} small />
          </div>
        )}
      </ShortcutItemMenuButton>
    </div>
  );
};

export default ShortcutItem;
