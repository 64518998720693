import useSWR, { SWRResponse } from "swr";
import { IUseBank } from "./types";
import { IBank } from "@portal/types/api/IBank";
import { useSession } from "next-auth/react";
import commonSwrConfig from "../commonSwrConfig";

const useBank: IUseBank = () => {
  const { data: session } = useSession();
  const fetcher = async (url: URL): Promise<IBank> => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, isLoading, error }: SWRResponse<IBank, unknown> = useSWR(
    session?.centerId ? `/api/bank/${session?.centerId}` : null,
    fetcher,
    commonSwrConfig(),
  );

  return {
    data,
    error,
    isLoading,
  };
};

export default useBank;
