import { addDays, format } from "date-fns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreatedSavingsAgreement } from "@portal/types/api/ICreatedSavingsAgreement";
import { IModifySavingsAgreementFund } from "@portal/types/api/IModifySavingsAgreementFund";
import { IModifySavingsAgreement } from "@portal/types/api/IModifySavingsAgreement";
import { ICreateSavingsAgreement } from "@portal/types/api/ICreateSavingsAgreement";
import { GetInitialPeriodDayNumber } from "@portal/helpers/CalculateDates";
import { TextResources } from "@portal/assets/text/TextResources";
import { AllFundsHaveAnAmount, IsValidFundAmount } from "@portal/components/pages/agreements/shared/helpers/Validation";

export interface SavingsAgreementResponseObject {
  agreement: ICreatedSavingsAgreement | undefined;
}

export interface IFundAmount {
  isin: string;
  amount: number;
}

const agreementData = {
  advisorCarriedOut: false,
  advisorName: "",
  adjustmentComment: "Min spareavtale",
  cashAccount: null,
  dateFrom: format(new Date(), "yyyy-MM-dd"),
  dateTo: null,
  monthTable: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  nextDate: format(addDays(new Date(), 5), "yyyy-MM-dd"),
  periodDayNumber: GetInitialPeriodDayNumber(),
  periodType: "M",
  savingsAgreementDescription: "",
  funds: [],
  isSavingRobot: false,
  yearlyIncreasePercent: 0,
} as IModifySavingsAgreement;

const initialRequest = {
  portfolioId: "",
  agreementData,
} as ICreateSavingsAgreement;

const initialResponse = {
  agreement: undefined,
} as SavingsAgreementResponseObject;

const initialState = {
  request: initialRequest,
  response: initialResponse,
  suitabilityReport: [] as string[],
  isLoading: false,
  error: "",
  step: 1,
  selectedFundsError: "",
  missingAmountError: "",
  totalAmountError: "",
};

export const savingsAgreementReducer = createSlice({
  name: "savingsAgreement",
  initialState,
  reducers: {
    clearSavingAgreementData: state => {
      state.request = initialRequest;
      state.response = initialResponse;
      state.suitabilityReport = [];
      state.step = 1;
      state.error = "";
      state.isLoading = false;
      state.selectedFundsError = "";
      state.missingAmountError = "";
      state.totalAmountError = "";
    },
    setSavingsAgreementMonthTable: (state, action: PayloadAction<number[]>) => {
      state.request.agreementData.monthTable = action.payload;
    },
    setSavingsAgreementPortfolioId: (state, action: PayloadAction<string>) => {
      state.request.portfolioId = action.payload;
    },
    setSavingsAgreementName: (state, action: PayloadAction<string>) => {
      state.request.agreementData.adjustmentComment = action.payload;
    },
    setSavingsAgreementCashAccount: (state, action: PayloadAction<string>) => {
      state.request.agreementData.cashAccount = action.payload;
    },
    setSavingsAgreementAdvisorCarriedOut: (state, action: PayloadAction<boolean>) => {
      state.request.agreementData.advisorCarriedOut = action.payload;
    },
    setSavingsAgreementAdvisorName: (state, action: PayloadAction<string>) => {
      state.request.agreementData.advisorName = action.payload;
    },
    setSavingsAgreementPeriodDayNumber: (state, action: PayloadAction<number>) => {
      state.request.agreementData.periodDayNumber = action.payload;
    },
    setSavingsAgreementPeriodType: (state, action: PayloadAction<string>) => {
      state.request.agreementData.periodType = action.payload;
    },
    setSavingsAgreementStartDate: (state, action: PayloadAction<string>) => {
      state.request.agreementData.dateFrom = action.payload;
    },
    setSavingsAgreementEndDate: (state, action: PayloadAction<string | null>) => {
      state.request.agreementData.dateTo = action.payload;
    },
    setSavingsAgreementNextDate: (state, action: PayloadAction<string>) => {
      state.request.agreementData.nextDate = action.payload;
    },
    addSavingsAgreementFund: (state, action: PayloadAction<IModifySavingsAgreementFund>) => {
      state.request.agreementData.funds = [...state.request.agreementData.funds, action.payload];
      if (state.request.agreementData.funds.length > 0) state.selectedFundsError = "";
      else state.selectedFundsError = TextResources.AGREEMENT_MISSING_FUND;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setSavingsAgreementFundAmount: (state, action: PayloadAction<IFundAmount>) => {
      const { amount } = action.payload;
      const funds = state.request.agreementData.funds;

      funds.forEach(fund => {
        if (fund.isin === action.payload.isin) {
          state.request.agreementData.funds = funds.map(f => (f.isin === action.payload.isin ? { ...f, amount } : f));
        }
      });

      if (AllFundsHaveAnAmount(state.request.agreementData)) state.missingAmountError = "";
      else state.missingAmountError = TextResources.AGREEMENT_MISSING_AMOUNT;
      if (IsValidFundAmount(state.request.agreementData)) state.totalAmountError = "";
      else state.totalAmountError = TextResources.AGREEMENT_AMOUNT_BELOW_LIMIT;
    },
    removeSavingsAgreementFund: (state, action: PayloadAction<string>) => {
      state.request.agreementData.funds = state.request.agreementData.funds.filter(fund => fund.isin !== action.payload);
      if (state.request.agreementData.funds.length > 0) state.selectedFundsError = "";
      else state.selectedFundsError = TextResources.AGREEMENT_MISSING_FUND;
      if (AllFundsHaveAnAmount(state.request.agreementData)) state.missingAmountError = "";
      else state.missingAmountError = TextResources.AGREEMENT_MISSING_AMOUNT;
      if (IsValidFundAmount(state.request.agreementData)) state.totalAmountError = "";
      else state.totalAmountError = TextResources.AGREEMENT_AMOUNT_BELOW_LIMIT;
    },
    removeSavingsAgreementFunds: state => {
      state.request.agreementData.funds = [];
    },
    setSavingsAgreementFunds: (state, action: PayloadAction<IModifySavingsAgreementFund[]>) => {
      state.request.agreementData.funds = action.payload;
    },
    setSelectedFundsError: (state, action: PayloadAction<string>) => {
      state.selectedFundsError = action.payload;
    },
    setMissingAmountError: (state, action: PayloadAction<string>) => {
      state.missingAmountError = action.payload;
    },
    setTotalAmountError: (state, action: PayloadAction<string>) => {
      state.totalAmountError = action.payload;
    },
    clearHasError: state => {
      state.error = "";
    },
    validate: state => {
      if (state.request.agreementData.funds.length > 0) state.selectedFundsError = "";
      else state.selectedFundsError = TextResources.AGREEMENT_MISSING_FUND;
      if (AllFundsHaveAnAmount(state.request.agreementData)) state.missingAmountError = "";
      else state.missingAmountError = TextResources.AGREEMENT_MISSING_AMOUNT;
      if (IsValidFundAmount(state.request.agreementData)) state.totalAmountError = "";
      else state.totalAmountError = TextResources.AGREEMENT_AMOUNT_BELOW_LIMIT;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setYearlyIncreasePercent: (state, action: PayloadAction<number>) => {
      state.request.agreementData.yearlyIncreasePercent = action.payload;
    },
    setResponse: (state, action: PayloadAction<SavingsAgreementResponseObject>) => {
      state.response = action.payload;
    },
    setSuitabilityReport: (state, action: PayloadAction<string[]>) => {
      state.suitabilityReport = action.payload;
    },
  },
});

export const {
  setSavingsAgreementPortfolioId,
  setSavingsAgreementName,
  setSavingsAgreementCashAccount,
  setSavingsAgreementAdvisorCarriedOut,
  setSavingsAgreementAdvisorName,
  setSavingsAgreementPeriodDayNumber,
  setSavingsAgreementPeriodType,
  setSavingsAgreementStartDate,
  setSavingsAgreementEndDate,
  setSavingsAgreementNextDate,
  addSavingsAgreementFund,
  setSavingsAgreementFundAmount,
  setSavingsAgreementMonthTable,
  setYearlyIncreasePercent,
  removeSavingsAgreementFund,
  removeSavingsAgreementFunds,
  clearHasError,
  setSavingsAgreementFunds,
  clearSavingAgreementData,
  setStep,
  setSelectedFundsError,
  setMissingAmountError,
  setTotalAmountError,
  validate,
  setLoading,
  setResponse,
  setSuitabilityReport,
} = savingsAgreementReducer.actions;
export default savingsAgreementReducer.reducer;
