import { MenuItem } from "@headlessui/react";
import { IShortcutItemProps } from "./types";
import Typography from "@portal/components/atoms/typography";
import { FC } from "react";
import { useRouter } from "nextjs-toploader/app";

const ShortcutItem: FC<IShortcutItemProps> = ({ item }) => {
  const router = useRouter();
  return (
    <MenuItem>
      {({ focus }) => (
        <button
          onClick={() => router.push(item.path)}
          className={`border-t ${focus ? "bg-blueHover" : ""} flex items-center w-full py-2 px-3.5`}
        >
          <div className="text-defaultText">
            <Typography variant="Bold">{item.displayText}</Typography>
          </div>
        </button>
      )}
    </MenuItem>
  );
};

export default ShortcutItem;
