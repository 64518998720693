import { useMemo } from "react";
import { mutate } from "swr";
import { ISelectedFilter } from "../../components/pages/funds/filterOptions/types";
import { IFund } from "../../types/api/IFund";
import { IFundFetcherResponse, IMutateFunds } from "./types";
import { IPortfolioDepotFeeSettings } from "@portal/types/api/IPortfolioDepotFeeSettings";
import { getFundFee } from "@portal/helpers/getFundFee";

export const filterAndSort = (
  field: keyof IFund,
  filters: ISelectedFilter[],
  desc = false,
  isinExcludeList?: string[],
  totalFundValue?: number,
  fundCosts?: IPortfolioDepotFeeSettings,
  cachedData?: IFundFetcherResponse,
) => {
  if (!cachedData) return undefined;
  const filteredFunds = new Set<IFund>(cachedData.allFunds.filter(f => !isinExcludeList?.includes(f.isin)));
  filteredFunds.forEach(fund => {
    filters.forEach(filter => {
      if (filter.items.length === 0) return;
      const value = fund[filter.fieldId]?.toString();
      if (filter.search) {
        if (!value || !filter.items.find(fi => value.toLowerCase().includes(fi.value.toLowerCase()))) {
          filteredFunds.delete(fund);
        }
      } else if (!value || !filter.items.map(fi => fi.value).includes(value)) {
        filteredFunds.delete(fund);
      }
    });
  });

  const mappedFunds = Array.from(filteredFunds).map(fund => {
    const fundFee = getFundFee(fund.custodyFeeType, totalFundValue ?? 0, fundCosts);
    const customerPrice = fund.nettoFeePercent + (fundFee ?? 0);
    return {
      ...fund,
      fundFee,
      customerPrice,
    };
  });

  const sortedFunds = mappedFunds.sort((a, b): number => {
    if (typeof a[field] === "string" || typeof b[field] === "string") {
      const columnA = a[field] as string;
      const columnB = b[field] as string;
      return desc ? columnB.localeCompare(columnA) : columnA.localeCompare(columnB);
    }

    if (typeof a[field] === "number" || typeof b[field] === "number") {
      if (typeof a[field] !== "number") {
        return desc ? 1 : -1;
      }
      if (typeof b[field] !== "number") {
        return desc ? -1 : 1;
      }
      const columnA = a[field] as number;
      const columnB = b[field] as number;
      return desc ? columnB - columnA : columnA - columnB;
    }

    if (typeof a[field] === "boolean" || typeof b[field] === "boolean") {
      return a[field] && desc ? 1 : a[field] ? -1 : 1;
    }

    return 0;
  });

  return {
    funds: sortedFunds,
    allFunds: cachedData.allFunds,
  };
};

const useMutateFunds: IMutateFunds = (centerId: string) => {
  const fns = useMemo(() => {
    const filterAndSortFunds = (
      field: keyof IFund,
      filters: ISelectedFilter[],
      desc = false,
      isinExcludeList?: string[],
      totalFundValue?: number,
      fundCosts?: IPortfolioDepotFeeSettings,
    ) => {
      return mutate(
        `/api/funds?centerId=${centerId}`,
        async (cachedData?: IFundFetcherResponse): Promise<IFundFetcherResponse | undefined> => {
          return filterAndSort(field, filters, desc, isinExcludeList, totalFundValue, fundCosts, cachedData);
        },
        { revalidate: false },
      );
    };

    return {
      filterAndSortFunds,
    };
  }, [centerId]);
  return fns;
};

export default useMutateFunds;
