import { IPortfolioDepotFeeSettings } from "@portal/types/api/IPortfolioDepotFeeSettings";

export const getFundFee = (
  custodyFeeType: string,
  totalFundValue: number,
  fundCosts?: IPortfolioDepotFeeSettings,
): number | undefined => {
  if (fundCosts?.depotFees === undefined) return undefined;
  if (fundCosts.fixedDepotFeePercent !== undefined && fundCosts.fixedDepotFeePercent !== null)
    return fundCosts.fixedDepotFeePercent;
  let fee = fundCosts.depotFees.find(df => df.depotFeeType === "FUND_FEE_HIGH");
  if (custodyFeeType === "Low") {
    fee = fundCosts.depotFees.find(df => df.depotFeeType === "FUND_FEE_LOW");
  }

  const depotFeeStep = fee?.depotFeeSteps.reduce(
    (acc, curr) => {
      if (totalFundValue >= curr.stepLimit && curr.stepLimit >= acc.stepLimit) {
        return curr;
      }
      return acc;
    },
    { depotFeePercent: 0, stepLimit: 0 },
  );
  return depotFeeStep?.depotFeePercent;
};
