import useSWR, { SWRResponse } from "swr";
import { IUseSelectedUserPoaForLoggedInUser } from "./types";
import commonSwrConfig from "../commonSwrConfig";
import useSelectedPowerOfAttorney from "../useSelectedPowerOfAttorney";
import { IPoaInfo } from "@portal/types/api/IPoaInfo";

const useSelectedUserPoaForLoggedInUser: IUseSelectedUserPoaForLoggedInUser = () => {
  const { data: selectedUser } = useSelectedPowerOfAttorney();
  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  };
  const { data, error, isLoading }: SWRResponse<IPoaInfo[], unknown> = useSWR(
    selectedUser?.userId ? "/api/powerOfAttorney/poaLoggedInUser" : null,
    fetcher,
    commonSwrConfig(),
  );
  return {
    data,
    selectedUser,
    isLoading,
    error,
  };
};
export default useSelectedUserPoaForLoggedInUser;
