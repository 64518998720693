import { FC } from "react";
import { IStockSearchItemProps } from "./types";

const StockSearchItem: FC<IStockSearchItemProps> = ({ item }) => {
  const renderElement = (text: string) => (
    <span className="flex items-center">
      {item.flag && <span className={`cell-flag cell-flag-${item.flag.toLowerCase()}`}></span>}
      {text}
    </span>
  );

  if (!item.name) {
    return renderElement(item.ticker);
  }

  return (
    <span className="flex items-center justify-between">
      {renderElement(item.name)}
      <span>{item.ticker}</span>
    </span>
  );
};

export default StockSearchItem;
