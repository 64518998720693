import { IFund } from "@portal/types/api/IFund";

export const mapFund = (apiFund: IFund): IFund => {
  const nettoFeePercent = apiFund ? apiFund.feePercent - apiFund.refundProvisionPercent : 0;
  return {
    ...apiFund,
    returnPercentOneDay: apiFund.fundReturnInfo?.returnPercentOneDay,
    returnPercentYearToDate: apiFund.fundReturnInfo?.returnPercentYearToDate,
    returnPercentOneMonth: apiFund.fundReturnInfo?.returnPercentOneMonth,
    returnPercentOneYear: apiFund.fundReturnInfo?.returnPercentOneYear,
    returnPercentThreeYearsAnnualized: apiFund.fundReturnInfo?.returnPercentThreeYearsAnnualized,
    returnPercentFiveYearsAnnualized: apiFund.fundReturnInfo?.returnPercentFiveYearsAnnualized,
    returnPercentTenYearsAnnualized: apiFund.fundReturnInfo?.returnPercentTenYearsAnnualized,
    sortKey: apiFund.sortKey ?? 999999,
    nettoFeePercent,
  };
};
