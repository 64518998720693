import { useRouter } from "nextjs-toploader/app";
import { FC } from "react";
import { NotificationItem } from "@portal/components/atoms/notificationItem";
import { IShortcutItem } from "../../desktopShortcuts/shortcutItem/types";

const Item: FC<IShortcutItem> = ({
  displayText,
  path,
  hideAtSmallDevice,
  hideAtMediumDevice,
  notificationCount,
  showNotifications,
}) => {
  const router = useRouter();
  return (
    <div
      className={`${
        hideAtMediumDevice ? `hidden` : hideAtSmallDevice ? `hidden sm:flex` : `flex`
      } text-headerMenuText hover:text-secondary`}
    >
      <div className="flex items-start" onClick={() => router.push(path)}>
        <div className="flex text-sm sm:text-base">{displayText}</div>
        {showNotifications && (
          <div className="flex pb-1 sm:pb-1.5 ml-[-2px]">
            <NotificationItem count={notificationCount} showNotifications={showNotifications} small />
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
