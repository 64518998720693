import MenuGroup from "./MenuGroup";
import useBank from "@portal/hooks/useBank";
import { MenuItems as HeadlessMenuItems } from "@headlessui/react";
import { TextResources } from "@portal/assets/text/TextResources";
import { accountItems, fundItems, myPagesItems, otherItems, stockItems } from "./mainMenuConfig";
import useIsCorporateUser from "@portal/hooks/useIsCorporateUser";
import useNotificationCount from "@portal/hooks/useNotificationCount";

export const ResponsiveMenu = () => {
  const { data: bankData } = useBank();
  const { showNotifications, notificationCount } = useNotificationCount();

  const savingRobotEnabled = bankData?.savingRobotEnabled;
  const { isCorporateUser } = useIsCorporateUser();

  return (
    <div className="absolute left-0 w-full bg-headerBackground text-headerMenuText z-10">
      <HeadlessMenuItems>
        <div className="h-[100vh] mx-auto container px-3">
          <div className="space-y-3 md:space-y-0 flex flex-col md:flex-row md:gap-10 lg:gap-20 pt-5 justify-between">
            <MenuGroup
              title={TextResources.MAIN_MENU_MY_PAGES_HEADER}
              icon="homeIcon"
              menuItems={myPagesItems}
              showNotifications={showNotifications}
              notificationCount={notificationCount}
            />
            {bankData?.fundTradingEnabled && (
              <MenuGroup
                title={TextResources.MAIN_MENU_FUND_HEADER}
                icon="circleStackIcon"
                menuItems={fundItems(savingRobotEnabled ?? false)}
                showNotifications={showNotifications}
                notificationCount={notificationCount}
              />
            )}
            {bankData?.stockTradingEnabled && (
              <MenuGroup
                title={TextResources.MAIN_MENU_STOCK_HEADER}
                icon="arrowTrendingIcon"
                menuItems={stockItems}
                showNotifications={showNotifications}
                notificationCount={notificationCount}
              />
            )}
            <MenuGroup
              title={TextResources.MAIN_MENU_ACCOUNT_HEADER}
              icon="bankNotesIcon"
              menuItems={accountItems(isCorporateUser, bankData?.fundTradingEnabled ?? false)}
              showNotifications={showNotifications}
              notificationCount={notificationCount}
            />
            <MenuGroup
              title={TextResources.MAIN_MENU_OTHER_HEADER}
              icon="ellipsisHorizontalCircleIcon"
              menuItems={otherItems(isCorporateUser)}
              showNotifications={showNotifications}
              notificationCount={notificationCount}
            />
          </div>
        </div>
      </HeadlessMenuItems>
    </div>
  );
};
