import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { IUseVerifyAmlStatus } from "./types";
import useAmlStatus from "../useAmlStatus";
import useElectronicIdentification from "../useElectronicIdentification";
import useAdvisor from "../useAdvisor";

const useVerifyAmlStatus: IUseVerifyAmlStatus = () => {
  const { data, error } = useAmlStatus();
  const router = useRouter();
  const pathname = usePathname();
  const [amlVerified, setAmlVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isAdvisor } = useAdvisor();
  const { data: eIdentityOk, isLoading: eIdentityLoading, error: eIdentityError } = useElectronicIdentification();

  useEffect(() => {
    if (data?.statusCode) {
      if (data?.statusCode.toLowerCase() === "ok") {
        if (!eIdentityLoading && !eIdentityError && !eIdentityOk) {
          window.location.href = `${process.env.NEXT_PUBLIC_ELECTRONIC_IDENTIFICATION_URL}?returnUrl=${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}${pathname}`;
        } else setAmlVerified(true);
      } else {
        if (isAdvisor) {
          setLoading(false);
          return;
        }
        if (data.statusCode.toLowerCase() === "expired" || data.statusCode.toLowerCase() === "doesnotexist") {
          router.push("/aml/form");
        } else if (data.statusCode.toLowerCase() === "awaitingenhancedcontrol") {
          router.push("/aml/waiting-control");
        } else if (data.statusCode.toLowerCase() === "rejected") {
          router.push("/aml/form");
        } else {
          // unknown aml status
          router.push("/aml/form");
        }
      }
      setLoading(false);
    } else if (pathname?.startsWith("/internal")) {
      setAmlVerified(true);
      setLoading(false);
    }
  }, [data, router, amlVerified, eIdentityLoading, eIdentityError, eIdentityOk, isAdvisor, pathname]);

  return { amlVerified, error, loading };
};

export default useVerifyAmlStatus;
