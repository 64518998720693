import { Dialog, DialogPanel } from "@headlessui/react";
import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";
import useCenterIdOpenPages from "@portal/hooks/useCenterIdOpenPages";
import { FC } from "react";

interface Props {
  open: boolean;
  panelClassName?: string;
  children: React.ReactNode;
  onClose: (value: boolean) => void;
}

export const DialogComponent: FC<Props> = ({ open, panelClassName = "", onClose, children }) => {
  const centerId = useCenterIdOpenPages();

  const theme = GetBankColorTheme(centerId);

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className={`${theme} text-defaultText`}>
        <div className="fixed inset-0 backdrop-blur-sm" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto">
          <DialogPanel className={`absolute ${panelClassName}`}>{children}</DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
