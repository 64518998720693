import { InfrontSDK, InfrontUtil } from "@infront/infront-sdk";
import { InfrontSDKSingleton } from "./infrontSDK";
import { Dispatch } from "@reduxjs/toolkit";

import {
  setName as setNameSimple,
  setTicker as setTickerSimple,
  setIsin as setIsinSimple,
  setDisplaySymbolStatus,
  setFlag as setFlagSimple,
} from "@portal/redux/reducers/api/infrontAllStocksSimple/reducer";

export const subscribeToFeed = (feedId: number, dispatch: Dispatch) => {
  InfrontSDKSingleton?.getInstance()?.get(
    InfrontSDK.feedContents({
      feed: feedId,
      subscribe: false,
      contentType: InfrontSDK.FeedContentType.SymbolData,
      onData: (data: InfrontUtil.ObservableArray<InfrontSDK.SymbolData>) => {
        data.observe(
          {
            reInit: (items: InfrontSDK.SymbolData[]) => {
              items.forEach((item, index) => {
                item.observe(InfrontSDK.SymbolField.Ticker, (value: number | string) => {
                  dispatch(setTickerSimple({ index: index, feed: feedId, value: value as string }));
                });
                item.observe(InfrontSDK.SymbolField.ISIN, (value: number | string) => {
                  if (value) {
                    dispatch(setIsinSimple({ index: index, feed: feedId, value: value as string }));
                  }
                });
                item.observe(InfrontSDK.SymbolField.FullName, (value: number | string) => {
                  dispatch(setNameSimple({ index: index, feed: feedId, value: value as string }));
                });
                item.observe(InfrontSDK.SymbolField.CountryFlag, (value: number | string) => {
                  dispatch(setFlagSimple({ index: index, feed: feedId, value: value as string }));
                });
                item.observe(InfrontSDK.SymbolField.DisplaySymbolStatus, (value: number | string) => {
                  dispatch(setDisplaySymbolStatus({ index: index, feed: feedId, value: value as string }));
                });
              });
            },
          },
          2,
        );
      },
      onError: error => console.error("Error:", error),
    }),
  );
};
