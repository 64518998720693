import Typography from "@portal/components/atoms/typography";
import BankLogoComponent from "@portal/components/atoms/bankLogo";
import DesktopShortcuts from "./desktopShortcuts";
import Icon from "@portal/components/atoms/icons";
import useAdvisor from "@portal/hooks/useAdvisor";
import { useRouter } from "next/navigation";
import { UserMenu } from "../UserMenu";
import { useSession } from "next-auth/react";
import { TextResources } from "@portal/assets/text/TextResources";
import { Menu as HeadlessMenu, MenuButton as HeadlessMenuButton } from "@headlessui/react";
import { ResponsiveMenu } from "./ResponsiveMenu";
import { FC } from "react";
import { INavBarProps } from "./types";
import SmallDeviceShortcuts from "./smallDeviceShortcuts";
import Search from "./search";

const NavBar: FC<INavBarProps> = ({ centerId }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const { isAdvisorAndNoImpersonation } = useAdvisor();

  return (
    <div className="container mx-auto">
      <HeadlessMenu as="div">
        {({ open }) => (
          <>
            <div className="flex justify-between px-3 md:px-0">
              <button onClick={() => router.push("/")}>
                <BankLogoComponent centerId={centerId} />
              </button>
              {!isAdvisorAndNoImpersonation && <DesktopShortcuts />}
              {!isAdvisorAndNoImpersonation ? (
                <div className="relative flex space-x-2 items-center">
                  <Search />
                  <div>
                    {session ? (
                      <div className="flex py-2">
                        <UserMenu centerId={centerId} />
                      </div>
                    ) : (
                      <div className="flex h-[50px] min-w-[75px] px-2">
                        <button onClick={() => router.push("/auth/signin")}>
                          <Typography variant="Bold">{TextResources.LOG_IN_PAGE_TITLE}</Typography>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex">
                    <HeadlessMenuButton>
                      <Icon name={open ? "xMarkIcon" : "barsIcon"} />
                    </HeadlessMenuButton>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    window.location.href = "/api/auth/logout";
                  }}
                  className="flex space-x-2 items-center"
                >
                  <Icon name="arrowRightOnRectangleIcon" size="xsmall" />
                  <Typography variant="Bold">{TextResources.MENU_LOGOUT}</Typography>
                </button>
              )}
            </div>
            {!isAdvisorAndNoImpersonation && <ResponsiveMenu />}
          </>
        )}
      </HeadlessMenu>
      <SmallDeviceShortcuts />
    </div>
  );
};

export default NavBar;
