import { TextResources } from "@portal/assets/text/TextResources";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import PortalPageBox from "@portal/components/atoms/portalPageBox";
import { TextLine } from "@portal/components/atoms/textLine";
import Typography from "@portal/components/atoms/typography";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";

const NoAccessToFeature = () => {
  const router = useRouter();
  const { status } = useSession();

  const renderPage = (title: string, ingress: string, buttonText: string, onClick: () => void) => (
    <PortalPageBox>
      <div className="flex flex-col justify-center items-center space-y-3">
        <div>
          <Typography variant="H6">{title}</Typography>
          <TextLine />
        </div>
        <Typography variant="Body">{ingress}</Typography>
        <div className="mt-5">
          <ButtonComponent text={buttonText} wide onClick={onClick} />
        </div>
      </div>
    </PortalPageBox>
  );

  if (status === "unauthenticated") {
    return renderPage(
      TextResources.INVALID_SESSION_PAGE_TITLE,
      TextResources.INVALID_SESSION_PAGE_TEXT,
      TextResources.INVALID_SESSION_RELOAD_PAGE_BUTTON_TEXT,
      () => {
        window.location.reload();
      },
    );
  }

  return renderPage(
    TextResources.NO_ACCESS_TO_FEATURE_PAGE_TITLE,
    TextResources.NO_ACCESS_TO_FEATURE_PAGE_TEXT,
    TextResources.NO_ACCESS_TO_FEATURE_GO_TO_MAIN_PAGE_BUTTON_TEXT,
    () => {
      router.push("/");
    },
  );
};

export default NoAccessToFeature;
