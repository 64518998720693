import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import riskLevelReducer from "./reducers/riskLevelReducer";
import savingCalculationsReducer from "./reducers/savingCalculationsReducer";
import savingProposalReducer from "./reducers/savingProposalReducer";
import languageReducer from "./reducers/languageReducer";
import ordersReducer from "./reducers/api/orders/reducer";
import positionsReducer from "./reducers/api/positions/reducer";
import switchFundReducer from "./reducers/ui/switchFund/reducer";
import savingsAgreementReducer from "./reducers/ui/createSavingsAgreement";
import updateSavingsAgreementReducer from "./reducers/ui/updateSavingsAgreement";
import infrontReducer from "./reducers/api/infront/reducer";
import infrontAllStocksSimpleReducer from "./reducers/api/infrontAllStocksSimple/reducer";

import switchOrderCountReducer from "./reducers/api/switchOrderCount/reducer";

const persistedReducers = combineReducers({
  riskLevel: riskLevelReducer,
  savingCalculations: savingCalculationsReducer,
  savingProposal: savingProposalReducer,
  savingsAgreement: savingsAgreementReducer,
  language: languageReducer,
});

const notPersistedReducers = combineReducers({
  ui: combineReducers({
    switchFund: switchFundReducer,
    updateSavingsAgreement: updateSavingsAgreementReducer,
  }),
  api: combineReducers({
    positions: positionsReducer,
    orders: ordersReducer,
    switchOrderCount: switchOrderCountReducer,
    infront: infrontReducer,
    infrontAllStocksSimple: infrontAllStocksSimpleReducer,
  }),
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, persistedReducers);
const rootReducer = combineReducers({
  persisted: persistedReducer,
  other: notPersistedReducers,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
